.configmail-header{
    background-color: #008080;
    color: white;
    height: 40px;
    padding: 8px;
    font-weight: bold
}
.configmail-table{
    width: 100%;
    border: 1px solid lightgray;
    /* box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 22%); */
    height: auto;
    padding-bottom: 30px; 
}
.configmail-input{
    display: block;
   margin: 20px;
}
.configmail-inputFields{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 50px;
    margin-top: 10px; 
}
.configmail-inputFields input{
    width: 235px;
    height: 30px;
}
.configmail-inputFields label{
    color: #555;
    width: 200px;
}
.configmail-inputFields >textarea{
    padding: 5px ;
    outline-color: rgb(143, 143, 143);
}
.configmail-inputFields >input[type="text"]{
    padding: 0px 5px;

}
.configmail-inputFields select{
    width: 235px;
    height: 30px;
     font-weight: bold;
     border: none;
    background-color:#E5E5E5;
    text-align: center;
}
.configmail-inputFields.innerinput{
    width: 50px;
    height: 20px;
    position: relative;
    left: 0px;
}
.configmail-inputFields{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;
    color:#555;
    margin: 20px 0px;
}
