@tailwind base;
/* 
@layer base {
  * {
    font-family: verdana, "sans-serif";
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Segoe UI";
    @apply font-semibold;
  }
  h1 {
    @apply lg:text-4xl sm:text-3xl min-[375px]:text-2xl text-xl;
  }
  h2 {
    @apply lg:text-3xl sm:text-2xl min-[375px]:text-xl text-lg;
  }
  h3 {
    @apply lg:text-2xl sm:text-xl min-[375px]:text-lg text-base;
  }
} */

@tailwind components;
@tailwind utilities;

.Input-Box {
  @apply w-full
  bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 pl-3 leading-8 transition-colors duration-200 ease-in-out;
}
.Input-Box-Dark {
  @apply w-full
  rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 pl-3 leading-8 transition-colors duration-200 ease-in-out;
}
.Input-Box-Support {
  @apply w-72
   rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 pl-3 leading-8 transition-colors duration-200 ease-in-out border-indigo-500;
}

.Input-Box-Email {
  @apply w-52 h-10
  bg-white rounded border -mx-4 border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out;
}
.header {
  @apply p-2 font-bold text-2xl;
}
.Card-layout {
  @apply shadow-2xl rounded-lg  px-3 -mt-4   py-1;
}
.Grid-addbtn {
  @apply mt-4 flex gap-7  items-end justify-end;
}
.text-last-center {
  text-align: start;
  text-align-last: center;
}
.input-field {
  @apply w-full h-9
  bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out;
}

.mySelect__menu-list {
  height: 200px;
}
.row-fail {
  font-weight: bold;
  background-color: #aa2e25 !important;
}

.row-pass {
  font-weight: bold;
  background-color: #357a38 !important;
}
.styleTitle {
  font-size: 25px;
}

.swal-title-color {
  /* height: 300px !important; */
  width: 300px !important;
}
.swal-title-icon {
  font-size: 10px !important;
}

.swal-container {
  z-index: 20000 !important;
}

.swal2-container {
  z-index: 20000 !important;
}

td,
th {
  padding: 5px;
  text-align: left;
}
/* .new-button {
  display: inline-block;
  padding: 8px 12px; 
  cursor: pointer;
  border-radius: 4px;
  background-color: #9c27b0;
  font-size: 16px;
  color: #fff;
} */
.choose-file {
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0;
  font-size: 15px;
  color: rgb(153, 153, 153);
}
/* .button-wrap {
position: relative;
} */
/* 
element.style {
  height: 30px;
}
.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  display: block;
}
.sun-editor .se-wrapper .se-wrapper-inner {
  width: 100%;
  height: 100%;
  min-height: 65px;
  overflow-y: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  user-select: auto;
  -o-user-select: auto;
  -moz-user-select: auto;
  -khtml-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
} */

/* .my-editor-class {
  border: 2px solid blue;
} */

.sun-editor {
  border: none !important;
}

.se-container {
  border-color: #e5e7eb !important;
  border: 2px solid #e5e7eb !important;
  border-radius: 6px !important;
}

a:se-container {
  border: 2px solid black !important;
}

.se-input-form,
.se-math-exp {
  max-height: 120px !important;
}

.ag-theme-alpine {
  width: 100%;
}
.ag-header-cell-resize {
  position: relative;
  z-index: 1;
}
.center-header .ag-header-cell-label {
  justify-content: center;
  display: flex;
  align-items: center;
}

.start-header {
  text-align: start;
}
/* Vendor-prefix for Mozilla Firefox */
/* input[type="datetime-local"]::-moz-clear {
  display: none !important;
} */

/* Vendor-prefix for Microsoft Edge and Internet Explorer */
/* input[type="datetime-local"]::-ms-clear {
  display: none !important;
} */
/* .btn {
  padding: 0.5rem 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn:hover {
  background-color: #2E8B57;
} */

.custom-toastify {
  background-color: #313131 !important;
  width: 450px !important;
  /* margin: auto; */
  /* color: white !important; */
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 8px;
}

.loading-spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none;
}

.api-call-in-progress {
  pointer-events: none;
  opacity: 0.4;
}

#webpack-dev-server-client-overlay {
  display: none;
}

.custom-swal {
  z-index: 9999 !important;
}
.se-btn-list {
  font-size: 14px !important;
  /* z-index: 10; */
}
.sun-editor .se-list-inner ul {
  height: 160px;
}
/* .col-span-10.mr-6 {
  z-index: 0;
} */

.bg-red {
  background-color: #de3163;
}

.recaptcha-wrapper {
  width: 100px;
  height: 40px; /* Adjust the height as needed */
  transform: scale(0.1); /* Adjust the scale to zoom out */
  transform-origin: 0 0; /* Ensure proper scaling from the top-left corner */
}

.recaptcha-wrapper div,
.recaptcha-wrapper iframe {
  transform: scale(1.4); /* Further adjust if needed for better fit */
  transform-origin: 0 0;
}

.recaptcha-wrapper > div {
  width: 100px !important;
  max-width: 100px !important;
}
.right-aligning-labels{
  text-align: right;
   display: block
}


.se-math-preview {
  overflow: auto !important;
}


.no-spin input::-webkit-outer-spin-button,
.no-spin input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spin input[type=number] {
  -moz-appearance: textfield;
}



